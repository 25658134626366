import { combineReducers } from 'redux'
import authReducer from './authReducer'
import productReducer from './productReducer'
import rollsReducer from './rollsReducer'
import settingsReducer from './settingsReducer'

export default combineReducers({
  settings: settingsReducer,
  auth: authReducer,
  product: productReducer,
  rolls: rollsReducer
})
