import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import store from './redux/store'
import 'bootstrap/dist/css/bootstrap.min.css';

import { I18nextProvider } from "react-i18next";
import i18next from "./lang/lang";
import { ToastContainer } from "react-toastify";

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <ToastContainer />
      <App />
    </I18nextProvider>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
