import { ADDTOCART, REMOVEFROMCART, EMPTYCART, UPDATECART } from '../types/productTypes'

const initialState = {
  cart: []
}

export default function productReducer(state = initialState, action) {
  switch (action.type) {
    case ADDTOCART:
      // localStorage.setItem("markaCart", [...state.cart, action.payload])
      return { ...state, cart: [...state.cart, {amount: 1, ...action.payload}]}
    case REMOVEFROMCART:
      // localStorage.setItem("markaCart", [...state.cart].filter(c => c.id != action.payload.id))
      return { ...state, cart: [...state.cart].filter(c => c.id !== action.payload.id)}
    case EMPTYCART:
      // localStorage.setItem("markaCart", [])
      return { ...state, cart: []}
    case UPDATECART:
      // localStorage.setItem("markaCart", [])
      let allCart = state.cart
      allCart[allCart.findIndex(c => c.id === action.id)].amount = action.amount
      return { ...state, cart: [...allCart]}
    default:
      return state
  }
}
