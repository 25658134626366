import { SET, UNFOLD, MODE, BRANCH } from '../types/settingsTypes'

const initialState = {
  sidebarShow: false,
  sidebarUnfoldable : false,
  mode: "admin",
  branch: "bishkek"
}

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case SET:
      return { ...state, sidebarShow: action.payload }
    case UNFOLD:
      return { ...state, sidebarUnfoldable: action.payload }
    case MODE:
      return { ...state, mode: action.payload }
    case BRANCH:
      return { ...state, branch: action.payload }
    default:
      return state
  }
}
