import { SETROLLS } from '../types/rollsTypes'

const initialState = {
  rolls: []
}

export default function rollsReducer(state = initialState, action) {
  switch (action.type) {
    case SETROLLS:
      return { ...state, rolls: action.payload}
    default:
      return state
  }
}
